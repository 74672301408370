import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Chat from "./Components/Chat/Chat";
import Chatarea from "./Components/Chat/Chatarea";
import Login from "./Components/Login/Login";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "./Redux/actions/userAction";
import toast, { Toaster } from "react-hot-toast";
import { useEffect } from "react";
import { ProtectedRoute } from "protected-route-react";
import { io } from "socket.io-client";
import Profile from "./Components/Chat/Profile";
import Dashboard from "./Components/Chat/Dashboard";
import Register from "./Components/Register/Register";
import UpdateProfile from "./Components/Update Profile/UpdateProfile";
import ChangePassword from "./Components/Change Password/ChangePassword";
import Loader from "./Components/Loader/Loader"
import { serverSocket } from "./Redux/store";



function App() {
  const { isAuthenticated, message, error, loading } = useSelector(
    (state) => state.profile
  );

  const user = useSelector((state) => state.profile.user);

  const dispatch = useDispatch();

  const socket = io(`${serverSocket}`, {
    withCredentials: true,
  });


  function removeHTMLStyling(text) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/html');
    return doc.body.textContent || "";
  }

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: "clearError" });
    }
    if (message) {
      toast.success(message);
      dispatch({ type: "clearMessage" });
    }
  }, [dispatch, error, message]);

  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  useEffect(() => {
    if (Notification.permission !== "granted") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
        }
      });
    }
  }, []);

  const showNotification = (message, avatar, senderName) => {
    if (Notification.permission === "granted") {
      const notification = new Notification("New Message", {
        body: senderName + " " + " : " + " " + message,
        icon: avatar,
      });

      notification.onclick = () => {
        window.open("http://localhost:3000/chat");
      };
    }
  };

  const showGroupNotification = (message, avatar, senderName, groupName) => {
    if (Notification.permission === "granted") {
      const notification = new Notification(`${groupName}`, {
        body: senderName + " " + " : " + " " + message,
        icon: avatar,
      });

      notification.onclick = () => {
        window.open("http://localhost:3000/chat");
      };
    }
  };


  useEffect(() => {
    // Handle socket.io events
    socket.on('connect', () => {
      // console.log('Socket.io connection opened');
    });

    socket.on("Messages", (data) => {
      if (data && data.messageData.receiver === user._id) {
        let plainText = removeHTMLStyling(data.messageData.text);
        showNotification(
          plainText,
          data.messageData.sender.avatar.url,
          data.messageData.sender.name,
          data.messageData.receiver
        );
      }
    });

    socket.on('disconnect', () => {
      // console.log('Socket.io connection closed');
    });

    return () => {
      socket.disconnect();
    };
  }, [socket]);

  useEffect(() => {

    socket.on('connect', () => {
      // console.log('Socket.io connection opened');
    });

    socket.on("GroupMessages", (data) => {
      // console.log("data group messages==>", data);
      const receivers = data.groupMessage.receivers;

      {
        receivers.map((i) => {
          if (i !== data.groupMessage.sender._id && i === user._id) {
            let groupName = removeHTMLStyling(data.groupMessage.groupName);
            let plainText = removeHTMLStyling(data.groupMessage.text);
            showGroupNotification(
              plainText,
              data.groupMessage.sender.avatar.url,
              data.groupMessage.sender.name,
              groupName,
              i
            );
          } 
        })
      }

      
    });

    socket.on('disconnect', () => {
      // console.log('Socket.io connection closed');
    });

    return () => {
      socket.disconnect();
    };
  }, [socket]);

  return (
    <BrowserRouter>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute
                  isAuthenticated={!isAuthenticated}
                  redirect="/chat"
                >
                  <Login />
                </ProtectedRoute>
              }
            />
            <Route
              path="/chat"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated} redirect="/">
                  <Chat />
                </ProtectedRoute>
              }
            />
            <Route
              path="/chat/:userId"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated} redirect="/">
                  <Chatarea />
                </ProtectedRoute>
              }
            />
            <Route
              path="/me"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated} redirect="/">
                  <Profile user={user} />
                </ProtectedRoute>
              }
            />

            <Route
              path="/me/update"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated} redirect="/">
                  <UpdateProfile user={user} />
                </ProtectedRoute>
              }
            />

            <Route
              path="/password/update"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated} redirect="/">
                  <ChangePassword user={user} />
                </ProtectedRoute>
              }
            />

            <Route
              path="/admin/dashboard"
              element={
                <ProtectedRoute
                  adminRoute={true}
                  isAuthenticated={isAuthenticated}
                  redirect="/"
                  isAdmin={user && user.role === "admin"}
                >
                  <Dashboard />
                </ProtectedRoute>
              }
            />

            <Route
              path="/admin/add/user"
              element={
                <ProtectedRoute
                  adminRoute={true}
                  isAuthenticated={isAuthenticated}
                  redirect="/"
                  isAdmin={user && user.role === "admin"}
                >
                  <Register />
                </ProtectedRoute>
              }
            />
          </Routes>
          <Toaster />
        </>
      )}
    </BrowserRouter>
  );
}

export default App;

import { configureStore } from "@reduxjs/toolkit";
import { profileReducer, updateProfileReducer } from "./reducers/profileReducer";
import { groupMemberReducer, lastMessageReducer, myLastMessageReducer, updateGroupMemberReducer, usersCreateGroupReducer, usersGroupReducer, usersReducer } from "./reducers/usersReducer";
import { messageReducer } from "./reducers/profileReducer";
import { adminReducer } from "./reducers/adminReducer";


const store = configureStore({
    reducer:{
       profile:profileReducer,
       users:usersReducer,
       messages:messageReducer,
       lastMessages:lastMessageReducer,
       myLastMessageReducer:myLastMessageReducer,
       addUser:adminReducer,
       updateProfile:updateProfileReducer,
       usersGroup: usersGroupReducer,
       createGroup: usersCreateGroupReducer,
       groupMemberReducer: groupMemberReducer,
       updateGroupMember: updateGroupMemberReducer
    }
});


export default store

export const server = 'https://mern-chat-backend-kb2g.onrender.com/api/v1'

export const serverSocket = 'https://mern-chat-backend-kb2g.onrender.com'